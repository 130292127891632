<template>
  <!-- 电子遊艺/分類列表 -->
  <div
    id="slot"
    v-scroll.self="onScroll"
    class="h-100dvh overflow-y-auto overflow-x-hidden"
  >
    <!-- SEARCH MODE 搜尋模式 -->
    <SearchBar2>
      <!-- 搜尋輸入框 -->
      <v-text-field
        id="searchInput"
        slot="input"
        v-model.trim="form.filter"
        autofocus
        class="pa-0"
        hide-details
        height="36"
        :placeholder="$t('game.searchGame')"
        rounded
        autocomplete="off"
        dense
      />

      <!-- 刪除輸入文字 -->
      <v-btn
        v-if="form.filter"
        slot="clearInputTest"
        icon
        @click="clearFilterKeyword"
      >
        <v-icon size="16">
          mdi-close-circle
        </v-icon>
      </v-btn>
    </SearchBar2>
    <!--加載中 -->
    <v-col
      v-if="loading"
      cols="12"
      class="text-center pb-0"
    >
      <v-progress-circular
        indeterminate
        color="primary"
      />
    </v-col>
    <!--加載完成 -->
    <v-container
      v-else
      class="px-0 py-0"
    >
      <v-row
        v-if="searchResolve"
        class="px-1"
      >
        <v-col class="caption comment--text">
          <!-- 已搜尋 -->
          <div
            class="search__descrition-search"
          >
            <!-- 共搜索到 -->
            {{ $t('game.resultsFound') }}
            <span class="primary--text subtitle-2">
              {{ games.length }}
            </span>
            <!-- 条关于“花”的结果 -->
            {{ $t('game.aboutKeyword', { keyword: form.filter }) }}
          </div>
        </v-col>
      </v-row>

      <!-- 如果沒有遊戲 -->
      <template v-if="games.length === 0">
        <Nodata
          class="mt-11"
          :text="$t('game.noGames', { type: tabItem[tab] })"
        />
      </template>

      <!-- 如果有搜尋到遊戲 -->
      <template v-else>
        <GameList
          :games="games"
          :scroll-bottom="scrollBottom"
          :active-page="activePage"
          @favorite-game-toggle="favoriteGameToggle"
        />
      </template>
    </v-container>
  </div>
</template>

<script>
import slotMixin from '../views/slot/mixins/slotMixin'

export default {
  mixins: [slotMixin],
}
</script>

<style lang="scss" scoped>
.top-0 {
	top: 0;
}
.row + .row {
    margin-top: 0;
}
</style>
