<template>
  <!-- Game Type7 (分類版塊) -->
  <div
    :id="'game-' + data.block"
    class="game-type7 my-2"
  >
    <!-- <div class="bbb">
      htregdscvdbghret
    </div>
    <div class="eee">
      gbfdesxcdfvg
    </div> -->
    <div
      class="swiperBox d-flex"
      :style="{'height':`${swiperHeight}px`}"
    >
      <!-- 左邊tab -->
      <swiper
        ref="gameItem"
        :options="tabOption"
        class="myTab py-0"
      >
        <swiper-slide
          v-for="(item,index) in data.data.navbar"
          :key="item._id"
          style="width:auto"
        >
          <div
            class="game__item--card cursor-pointer"
            @click="getGameType('tab',index)"
          >
            <div
              class="game-card d-flex justify-center flex-column align-center pt-1"
              :class="{ 'swiper-slide-active': tabIndex === index }"
            >
              <span
                class="imgBox"
                :class="[tabIndex === index ? 'primary' : '']"
              >
                <img
                  v-if="navIcon(item)"
                  :src="baseImgUrl+navIcon(item)"
                  height="25"
                >
                <Icon
                  v-else
                  :data="icons[item.class]"
                  width="20"
                  height="26"
                  class="bg--text"
                />
              </span>

              <strong
                :class="[tabIndex === index ? 'primary--text' : 'subtitle--text']"
              > {{ item.game_type }}</strong>
            </div>
          </div>
        </swiper-slide>
      </swiper>
      <!-- 右邊滑動區塊 -->
      <swiper
        ref="gameItem"
        :options="swiperOption"
        class="mySwiper pl-1"
      >
        <swiper-slide
          style="width:auto"
        >
          <!-- 如果是電子或捕魚 -->
          <!-- TAB -->
          <v-tabs
            v-if="activeNavbarType"
            v-model="subTabIndex"
            class="game__tab"
            height="38"
            background-color="bg"
            color="primary"
          >
            <v-tab
              v-for="(game,index) in activeNavbarContent"
              :key="game._id"
              class="px-0"
              @click="getGameType('subTab',index)"
            >
              <span
                class="caption"
                :class="[subTabIndex === index ? 'primary--text' : 'subtitle--text']"
              >{{ game.title }}</span>
            </v-tab>
          </v-tabs>

          <SlotTemplate
            v-if="activeNavbarType"
            class="Items3"
            :style="`height: ${swiperHeight - 40}px` "
            :active-sub-tab-type="activeSubTabType"
            :active-page="activeNavbarType"
          />
          <v-row
            v-else
            class="px-4 pt-2"
            style="overflow: auto;"
          >
            <v-col
              v-for="(item,index) in activeNavbarContent"
              :key="item._id"
              :cols="activeNavbarType?4:6"
              class="game__item--card cursor-pointer d-flex justify-center flex-column align-center"
              :class="activeNavbarType?'cols4':'cols6'"
              :style="activeNavbarType ? { height: '30vw' } : { height: '37vw' }"
            >
              <div
                :class="item.status==='0'?'maintenanceBox':''"
                class="itemContent d-flex flex-column justify-center align-center"
                :style="gradientBg"
              >
                <!-- 維修中 父層要加 :class="item.status==='0'?'maintenanceBox':''"-->
                <maintenance v-if="item.status=='0'" />
                <!-- 如果不是捕漁、電子 -->
                <strong
                  v-if="!activeNavbarType"
                  class="white--text"
                >{{ item.title }}</strong>
                <img
                  v-if="!activeNavbarType"
                  :src="baseImgUrl+item.image_h5"
                  width="100%"
                  @click="lauchGame(item.game_code, item.hyperlink,item.class)"
                >
                <!-- 如果是捕漁、電子 -->
                <img
                  v-if="activeNavbarType"
                  :src="baseImgUrl+item.game.image"
                  width="100%"
                  @click="lauchGame(item.game_code, item.hyperlink,item.class)"
                >
              </div>
              <strong
                v-if="activeNavbarType"
                :class="[subTabIndex === index ? 'primary--text' : 'subtitle--text']"
              >{{ item.localized_name }}</strong>
            </v-col>
          </v-row>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { goSlotPage } from '../../../../utils/slot'
import { resStatus } from '../../../../utils/resUtils'
import maintenance from '@/components/maintenance'
import SlotTemplate from '@/components/SlotTemplate'
import esport from '@/assets/icons/block/game/type2/esport.svg'
import fish from '@/assets/icons/block/game/type2/fish.svg'
import live from '@/assets/icons/block/game/type2/live.svg'
import lottery from '@/assets/icons/block/game/type2/lottery.svg'
import slot from '@/assets/icons/block/game/type2/slot.svg'
import sport from '@/assets/icons/block/game/type2/sport.svg'
import card from '@/assets/icons/block/game/type2/card.svg'
export default {
  components: { maintenance, SlotTemplate },
  props: {
    data: {
      type: Object,
      required: true,
    },
    // 寫死fix區塊總高度(下載app/navbar/fifaWorldCup)
    fixLayoutHeight: {
      type: Number,
      required: true,
    },
    // 需fix的動態元件總高度(banner/maraquee/
    apiFixComponentsHeight: {
      type: Number,
      required: true,
    },
    // fix的動態元件是否載入完成
    isParentRendered: {
      type: Boolean,
      required: true,
    },
    // 動態模塊數量
    layoutAreaLength: {
      type: Boolean,
      required: true,
    },

  },

  data: () => ({
    tabOption: {
      slidesPerView: 'auto',
      centeredSlidesBounds: true,
      direction: 'vertical', // 垂直滚动
      autoHeight: true, // 高度自适应
      observer: true,
      observeParents: true,
      pagination: { el: '.swiperOption' },
    },
    swiperOption: {
      slidesPerView: 'auto',
      centeredSlidesBounds: true,
      autoHeight: true, // 高度自适应
      observer: true,
      observeParents: true,
      effect: 'slide', // 切换效果 "coverflow"（3d流）
      grid: {
        fill: 'row',
        rows: 2,
      },
    },
    tabIndex: 0,
    subTabIndex: 0,
    maintenance: false, // 維修中
    icons: {
      esport,
      fish,
      live,
      lottery,
      slot,
      sport,
      card,
    },

  }),
  computed: {
    ...mapGetters(['baseImgUrl', 'theme', 'gradientBg']),
    ...mapState({
      isLogin: state => state.user.isLogin,
      isLiffMode: state => state.line.liff.status,
    }),
    /**
     * 目前左邊主頁籤 active 的 class 是否是slot或fish
     * @date 2021-09-10
     * @returns {boolean} slot、fish＝＝ture
     */
    activeNavbarType() {
      const NavbarType = this.data.data.navbar[this.tabIndex].class
      return NavbarType === 'slot' || NavbarType === 'fish'
    },
    /**
     * 目前左邊主頁籤 active 的 vendor
     * @date 2021-09-10
     * @returns {array} 所有 vendor
     */
    activeNavbarContent() {
      return this.data.data.navbar[this.tabIndex].items
    },
    /**
     * 目前上方子當前頁籤的遊戲廠商（hyperlink）
     * @date 2024-08-10
     * @returns {string} 當前遊戲廠商（hyperlink）
     */
    activeSubTabType() {
      if (this.activeNavbarType) return this.activeNavbarContent[this.subTabIndex].hyperlink
      return 'noData'
    },

    /**
     * 取得allGameList高度
     * @date 2023-04-17
     */
    swiperHeight() {
      const { apiFixComponentsHeight, fixLayoutHeight, currentDevice } = this
      const bottomNavigationHeight = (this.currentDevice === 'ios' ? 72 : 56) +
      (this.layoutAreaLength ? 50 : 10)
      return window.innerHeight - apiFixComponentsHeight - fixLayoutHeight - bottomNavigationHeight
    },
  },
  mounted() {
    // 监听窗口大小变化事件
    // window.addEventListener('resize', this.swiperHeight)

  },
  methods: {
    ...mapActions([
      'get_game_url',
      'show_alert',
      'set_progress_status',
      'get_game_url_by_device',
    ]),
    // 設置為 search 模式
    setSearchMode() {
      this.mode = 'search'
      this.form.page = 1
      if (this.layout !== 'template7') this.games = []
    },
    /**
     * 取得當前遊戲類型
     * @param {string} tab 左邊主頁籤 subTab 上方子頁籤
     * @param {number} index 當前遊戲
     * @date 2024-04-10
     */
    getGameType(tab, index) {
      switch (tab) {
        case 'tab': {
          // 取得當前左邊主頁籤
          this.subTabIndex = 0
          this.tabIndex = index
          break
        }
        case 'subTab': {
          // 取得當前上方子頁籤
          this.subTabIndex = index
          break
        }
        default: {
          break
        }
      }
    },
    /**
     * 判斷現在是亮版或暗版 決定icon
     * @date 2023-01-08
     */
    navIcon(item) {
      console.log('3eerwef', item)
      return this.theme === 'dark' ? item.nav_icon_dark || '' : item.nav_icon_light || ''
    },
    /**
     * 啟動遊戲 OR slot 列表頁
     * @date 2021-07-02
     * @param {string} gameCode 遊戲代碼
     * @param {string} hyperlink 遊戲代碼
     */
    lauchGame(gameCode, hyperlink, gameType) {
      // 如果尚未登入
      if (!this.isLogin) {
        return this.show_alert({
          icon: 'fail',
          text: this.$t('flashMessage.loginFirst'),
        })
      }
      // 如果為 slot fish 爾且不是遊戲清單，就到 slot 頁面
      const goSlotPageItems = ['SLOT', 'FISH']
      if (goSlotPageItems.includes(gameType)) return this.goSlotPage(hyperlink)

      console.log(`開啟遊戲 liff mode:${this.isLiffMode}`)

      const ua = navigator.userAgent.toLowerCase()
      console.log(ua)
      console.log('LINE browser: ' + ua.indexOf('line'))

      // 不為 liff 模式下 也不為 line 開啟
      if (!this.isLiffMode || ua.indexOf('line') < 0) {
        if (hyperlink === '0') return false
        // 如果Tab是遊戲類型，gameType為 live || lottery 類型，則另開視窗 游戲
        if (gameType === 'LIVE' || gameType === 'LOTTERY') return this.goBlankPage(gameCode)
      }

      // 進入嵌入遊戲頁面
      this.$router.push({
        name: 'launchGame',
        query: {
          t: gameCode,
          redirectUrl: this.$route.path,
        },
      })
    },
    /**
     * 進入遊戲列表頁面
     * @date 2021-07-02
     * @param {string} hyperlink 遊戲代碼
     */
    goSlotPage(hyperlink) {
      goSlotPage(hyperlink)
    },
    /**
     * 另開遊戲視窗 (live)
     * @date 2021-08-04
     * @param {string} gameCode 遊戲代碼
     */
    async goBlankPage(gameCode) {
      this.set_progress_status(true)
      this.getGameUrl(gameCode)
    },
    /**
     * 取得遊戲 url (成功)
     * @date 2021-07-02
     * @param {object} gameUrl 遊戲連結 url
     */
    getGameUrlSuccess(gameUrl, windowObj) {
      this.show_alert({ icon: 'done' })
      this.gameUrl = gameUrl.url
      this.set_progress_status(false)
      windowObj.location.href = gameUrl.url
    },

    /**
     * 取得遊戲 url (失敗)
     * @date 2021-07-02
     */
    getGameUrlFail() {
      this.set_progress_status(false)
      this.show_alert({ icon: 'fail' })
      this.$router.replace({ path: '/' })
    },
    /**
     * 取得遊戲 url
     * @date 2021-07-02
     * @param {string} gameCode 遊戲代碼
     */
    async getGameUrl(gameCode) {
      const windowObj = window.open('', '_blank')
      const res = await this.get_game_url_by_device({ gamecode: gameCode, is_mobile: 1 })
      resStatus(res, this.getGameUrlSuccess, this.getGameUrlFail, windowObj)
    },
  },
}
</script>

<style lang="scss" scoped>

.game-type7{
  // 左邊區塊
  .eee{
    background-color:var(--primary-color);
    color:var( --secondary-color);
  }
  .bbb{
    color:var( --secondary-color);
    background: linear-gradient(to top, var(--background2_1-color), var(--background2_2-color))
  }
  .myTab{
    width: 15%;
    min-width: 70px;
    padding-left: 16px;
    img{
      filter: grayscale(100%);
    }
    .swiper-slide .swiper-slide-active{
      img{
        filter: brightness(90);
      }
      .game-card{
        background-image: url('~@/assets/icons/block/game/type6/tabBgActive.png');
      }
    }
    .game-card{
      margin-bottom: 10px !important;
      .imgBox{
        border-radius: 10px;
        padding: 8px 8px 2px 8px;
        margin-bottom: 5px;
        box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
        img{
          max-height: 25px;
        }
      }
      strong{
        font-size: 12px;
      }
    }
  }
  // 右邊區塊
  .mySwiper{
    width: 85%;
    padding-right: 16px;
    .game__tab{
      position: relative;
      z-index: 2;
    }
    .v-app-bar.v-app-bar--fixed{
      position: static;

    }
    .game__item--card{
      position: relative;
      padding: 0;
      &.cols4{
        img{
          width: 170%;
        }
        strong{
          font-size: 12px;
        }
      }
      &.cols6{
       .itemContent{
          padding-top: 10px;
          min-height: 80%;
          min-width: 95%;
        }
        strong{
          width: 100%;
          text-align: left;
          padding-left: 30px;
        }
      }
      .itemContent{
          border-radius: 20px;
          overflow: hidden;
          margin-left: 8px;
      }

    }
  }
  .swiper-slide{
    width: 100% !important;
  }
}
::v-deep .v-toolbar__content{
  padding: 0 !important;
}
</style>
