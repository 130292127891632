var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "scroll",
          rawName: "v-scroll.self",
          value: _vm.onScroll,
          expression: "onScroll",
          modifiers: { self: true },
        },
      ],
      staticClass: "h-100dvh overflow-y-auto overflow-x-hidden",
      attrs: { id: "slot" },
    },
    [
      _c(
        "SearchBar2",
        [
          _c("v-text-field", {
            staticClass: "pa-0",
            attrs: {
              slot: "input",
              id: "searchInput",
              autofocus: "",
              "hide-details": "",
              height: "36",
              placeholder: _vm.$t("game.searchGame"),
              rounded: "",
              autocomplete: "off",
              dense: "",
            },
            slot: "input",
            model: {
              value: _vm.form.filter,
              callback: function ($$v) {
                _vm.$set(
                  _vm.form,
                  "filter",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "form.filter",
            },
          }),
          _vm.form.filter
            ? _c(
                "v-btn",
                {
                  attrs: { slot: "clearInputTest", icon: "" },
                  on: { click: _vm.clearFilterKeyword },
                  slot: "clearInputTest",
                },
                [
                  _c("v-icon", { attrs: { size: "16" } }, [
                    _vm._v(" mdi-close-circle "),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.loading
        ? _c(
            "v-col",
            { staticClass: "text-center pb-0", attrs: { cols: "12" } },
            [
              _c("v-progress-circular", {
                attrs: { indeterminate: "", color: "primary" },
              }),
            ],
            1
          )
        : _c(
            "v-container",
            { staticClass: "px-0 py-0" },
            [
              _vm.searchResolve
                ? _c(
                    "v-row",
                    { staticClass: "px-1" },
                    [
                      _c("v-col", { staticClass: "caption comment--text" }, [
                        _c(
                          "div",
                          { staticClass: "search__descrition-search" },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$t("game.resultsFound")) + " "
                            ),
                            _c(
                              "span",
                              { staticClass: "primary--text subtitle-2" },
                              [_vm._v(" " + _vm._s(_vm.games.length) + " ")]
                            ),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("game.aboutKeyword", {
                                    keyword: _vm.form.filter,
                                  })
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.games.length === 0
                ? [
                    _c("Nodata", {
                      staticClass: "mt-11",
                      attrs: {
                        text: _vm.$t("game.noGames", {
                          type: _vm.tabItem[_vm.tab],
                        }),
                      },
                    }),
                  ]
                : [
                    _c("GameList", {
                      attrs: {
                        games: _vm.games,
                        "scroll-bottom": _vm.scrollBottom,
                        "active-page": _vm.activePage,
                      },
                      on: { "favorite-game-toggle": _vm.favoriteGameToggle },
                    }),
                  ],
            ],
            2
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }